import React, {useState, useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import moment from "moment";
import HtmlParser from "react-html-parser";

function Recentpost({slug}) {
    const [Post, setPost] = useState(null);
    const [recntPost, setrecntPost] = useState([]);

    const fetchData = () => {
        fetch(`https://www.tekrevol.com/blogs/wp-json/wp/v2/posts?_embed&slug=${slug}`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPost(data[0])
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        fetchData()
    }, [slug])

    let currentID = Post ? Post.id : null;
    const getrecentData = () => {
        fetch(`https://www.tekrevol.com/blogs/wp-json/wp/v2/posts?_embed&per_page=4&exclude[]=${currentID}`)
            .then((res) => res.json())
            .then((res) => {
                setrecntPost(res)
            })
    }
    useEffect(() => {
        if (Post) {
            getrecentData()
        }
    }, [Post, slug])


    return (
        <>

            <div className="tekrevol_insights p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={10} lg={10} xl={8} xxl={8}>
                            <div className="example" data-text="Publications">
                                <h2>Recent Blogs</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_insights_item">
                            <div className="blog_section">
                                {recntPost?.map((value, i) => (
                                    <div className="blog_list">
                                        <div className="blog_title">
                                            <Link to={`/blog/${value.slug}`}>
                                                <h3>{HtmlParser(value.title.rendered)}</h3>
                                            </Link>
                                        </div>
                                        <div
                                            className="dates">{moment(value.date).format('MMMM d, YYYY')} </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );
}

export default Recentpost;